import React from "react"
import News from "../../../blocks/en/news_page"
import { Link, graphql } from "gatsby"

export const pageQuery = graphql`
query {
  news: newsYaml(slug: { eq: "saveljevs_transfers_to_riga" }) {
    ...NewsInfoEn
  }
}`

export default class NewsTextEn extends News {
  renderNews() {
    return (
      <div className="row">
        <div className="col-xl-12">
          <p>Aleksejs Saveljevs, a midfielder of the National Latvian team and Mantova 1911, officially signed a contract with Riga FC.</p>
        </div>
      </div>
    )
  }
}